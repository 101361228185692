const contracts = {
  // bsc testnet address
  // CREATOR_PROXY: "0xC7A5aDF1d22C11e37C0856Ca620288EfE92483E9",
  // MARKETPLACE: "0x85e9694d4C9194543c4D4Ee80A347a60Fa0E730B",
  // ERC20: "0xD693cd652D2EBb5c306204A86a62147E17766300",

  // Amoy testnet address
  // CREATOR_PROXY: "0xF195F02Dc108628d068Ce7C93173b640cA1f77a8",
  // MARKETPLACE: "0xAADf7B05D6D21e303e93a1AdDd94fD4F9EB6692e",
  // ERC20: "0xD693cd652D2EBb5c306204A86a62147E17766300",

  // Amoy
  WMATIC: "0x2bE23B2edfb88F922bA18e657C32092008131248",
  USDC: "0xcbb87ff264502c46f6B3ee5a9eec8392d00EDa89",
  // USDT: "0xcbb87ff264502c46f6B3ee5a9eec8392d00EDa89",

  // Binance testnet
  // WETH: "0x8a702EF7785Dd0E2C507099dA7151c0a86b7e48a",
  // USDC: "0x6bf429B4AB73a74F4554938BEb7458D93A49aED6",
  // USDT: "0xd1Fac5Ff5d03b70D3383909692d09Fc6d97c8219",

  //MATIC MAINNET
  CREATOR_PROXY: "0x1FEfB3231A92d7A68a95dCbd8adD7cc644094136",
  MARKETPLACE: "0x58aF0C5326EbD82b6c5b7b132385F0b27541D8f5",
  ERC20: "0xD693cd652D2EBb5c306204A86a62147E17766300",
  //Polygon USDT
  USDT: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
  
  //ETHEREUM MAINNET
 // CREATOR_PROXY: "0x1FEfB3231A92d7A68a95dCbd8adD7cc644094136",
 // MARKETPLACE: "0x58aF0C5326EbD82b6c5b7b132385F0b27541D8f5",
 // ERC20: "0xD693cd652D2EBb5c306204A86a62147E17766300",
  //ETHEREUM USDT
  //USDT: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
};

export default contracts;